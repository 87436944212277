import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import styled from 'styled-components';
import { TwitterLink, LinkedInLink, GithubLink } from '../templates/andyfischer-dev';
import Layout from '../templates/andyfischer-dev/home';
export const _frontmatter = {
  "title": "Andrew Fischer"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hi! I'm Andy. I'm a full-stack developer that specializes in Web architecture.`}</p>
    <p>{`Currently I'm at `}<a parentName="p" {...{
        "href": "https://www.shutterfly.com"
      }}>{`Shutterfly.com`}</a>{`, building
cloud-based web architecture, React.js based applications, and improved frontend infrastructure.
Prior to that I worked on various C++ based platforms. My work tends to focus in two areas:
graphic user interfaces and improving dev efficiency.`}</p>
    <p>{`You can find my CV `}<a parentName="p" {...{
        "href": "./cv"
      }}>{`here`}</a>{`.`}</p>
    <p>{`And find me on social media: `}<TwitterLink mdxType="TwitterLink">{`Twitter`}</TwitterLink>{`, `}<LinkedInLink mdxType="LinkedInLink">{`LinkedIn`}</LinkedInLink>{`, `}<GithubLink mdxType="GithubLink">{`Github`}</GithubLink></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      